import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import { DeviceService } from "services/DeviceService";

import useRequest from "hooks/useRequest";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import { Container, Box, Typography, FormControlLabel, Divider, Button, Grid, Switch, ListSubheader, List, ListItemText, Paper, IconButton } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { WSService } from "services/WSServices";
import "./styles.scss";
import { ProductService } from "services/ProductService";
import { styled } from '@mui/material/styles';
import ListItemProductTag from "shared/components/list-item-product-tag/ListItemProductTag";
import DeviceSelector from "components/Functionals/DeviceSelector";

let globalChannel = null;
let arrayTags = [];
const DeviceActivityView = (props) => {
  const history = useHistory();
  const [channel, setChannel] = useState(false);
  const [arrayProducts, setArrayProducts] = useState([]);
  const [myTime, setMyTime] = useState(new Date());
  const [arrayTagsSaving, setArrayTagsSaving] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    var timerID = setInterval(() => {
      tick();
      //console.log(arrayTags);
      //console.log(arrayTagsSaving);
    }, 200);

    return () => clearInterval(timerID);
  });



  const tick = () => {
    var date = new Date();
    setMyTime(date);
    /*arrayTags.forEach((tag, index) => {
      var oldTagdate = new Date(date.getTime()-1000);
      if(tag.date < oldTagdate){
        arrayTags.splice(index, 1)
      }
    });*/
  }

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'block',
    height: '300%',
    marginLeft: '25vw',
    marginTop: '25vh',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  const receiveEvent = useCallback((payload) => {
    const ws = WSService.getInstance();
    console.log("Message from socket");
    console.log(payload);

    if (payload.method === "SELFCHECKOUT" && payload.room && ws.inRoom(payload.room)) {
      if (payload.action === "reset") {
        arrayTags.splice(0, arrayTags.length);
      }
    }

    if (payload.method === "EVENT" && payload.room && ws.inRoom(payload.room)) {

      var antenna = 0;
      antenna = payload.params[0].antenna;
      if (antenna == 10) {
        setShowAlert(false);
      }
      if (antenna == 11) {
        setShowAlert(true);
      }

      removeByAttr(arrayTags, "antenna", antenna);
      console.log(payload.params);


      payload.params.forEach(param => {
        antenna = param.antenna;
        removeByAttr(arrayTags, "epc", param.epc);
        // arrayTags.splice(0, arrayTags.length);
        if (antenna !== 99) {
          if (param.epc != "ACTION:CLEAN") {

            var tag = {
              epc: param.epc,
              sku: param.sku,
              product: null,
              antenna: param.antenna
            }
            searchProduct(tag.sku, tag)
            arrayTags.push(tag)
          }
        }

      });
      console.log("ANTENA: " + antenna);
      updateListTags();
    }

  }, []);

  const updateListTags = () => {
    console.log("UPDATEEEE");
    let uniqueTags = arrayTags.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.epc === value.epc
      )))

    setArrayTagsSaving([...uniqueTags]);
    //setLastUpdate(new Date())
  }



  const searchProduct = (sku, tag) => {
    if (arrayProducts && arrayProducts.length > 0) {
      const product = arrayProducts.find(prod => prod.sku == sku);
      tag.product = product;
      console.log("producto encontrado, no busca")
    }

    if (!tag.product || tag.product == undefined) {
      console.log("Busca producto")
      ProductService.productBySKU(sku).then(prod => {
        tag.product = prod.results.results[0];
        arrayProducts.push(prod.results.results[0]);
      });
    }


  };

  useEffect(() => {
    console.log("SSSSSSss")
  }, [arrayTags])

  useEffect(() => {
    globalChannel = null;

    const ws = WSService.getInstance();
    ws.listen("message", receiveEvent);

    return () => {
      if (globalChannel) {
        //closeRoom();
      }
      ws.removeListener("message");
    };
  }, []);

  useEffect(() => {
    globalChannel = channel;
  }, [channel]);


  const handleGoBack = () => {
    history.push(`/admin/devices`);
  };

  const openRoom = () => {
    if (!device) {
      return;
    }

    const data = {
      devices: [device.id],
      type: "SELFCHECKOUT",
      ref: "",
      associate: false,
      priority: "RFID",
    };

    console.log("try open")

    DeviceService.startRead(data)
      .then((response) => {
        setChannel(response.channel);
        const ws = WSService.getInstance();
        ws.joinRoom(response.channel);

      })
      .catch((_) => { console.log("error") });

    const ws = WSService.getInstance();

    ws.setDevices(device);
  };

  const handleRemove = (index) => {
    arrayTags.splice(index, 1);
  }

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  var removeByAttr = function (arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (arr[i]
        && arr[i].hasOwnProperty(attr)
        && (arguments.length > 2 && arr[i][attr] === value)) {

        arr.splice(i, 1);

      }
    }
    return arr;
  }

  const CustomNoRowsOverlay = () => {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="50"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1, ml: 3 }}>No Items</Box>
      </StyledGridOverlay>
    );
  }

  const content = (
    <Container className="content">
      <div className="header">
        <DeviceSelector
          enabled={true}
          typeRead={"SELFCHECKOUT"}
          refRead={""}
          receiveEvent={receiveEvent}
        />
      </div>
      {showAlert &&
        <div className="alarm">
          <div className="alarm-content">
            Hilo detectado
          </div>
        </div>
      }
      <Grid container className="grid-item-view" sx={{ flexGrow: 1 }}>
        <Grid item xs={12} >
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid item className="grid-column" >
              ANTENNA 1
              <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {arrayTagsSaving.filter(tag => tag.antenna == 1).length > 0 && arrayTagsSaving.filter(tag => tag.antenna == 1).map((tag, index) => {
                  return (
                    <ListItemProductTag key={index + "antenna1"} tag={tag} />
                  )
                })}
              </List>
            </Grid>
            <Grid item className="grid-column">
              ANTENNA 2
              <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {arrayTagsSaving.filter(tag => tag.antenna == 2).length > 0 && arrayTagsSaving.filter(tag => tag.antenna == 2).map((tag, index) => {
                  return (
                    <ListItemProductTag key={index + "antenna1"} tag={tag} />
                  )
                })}
              </List>
            </Grid>

            <Grid item className="grid-column">
              ANTENNA 3
              <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {arrayTagsSaving.filter(tag => tag.antenna == 3).length > 0 && arrayTagsSaving.filter(tag => tag.antenna == 3).map((tag, index) => {
                  return (
                    <ListItemProductTag key={index + "antenna3"} tag={tag} />
                  )
                })}
              </List>
            </Grid>
          </Grid>
        </Grid>
        {/* {arrayTags.length == 0 && CustomNoRowsOverlay()} */}
      </Grid>

      <div className="count-view">Total de productos: {arrayTags.length}
        <IconButton aria-label="delete" onClick={()=> {
          arrayTags=[];
          setArrayTagsSaving([])
        }}>
          <DeleteIcon color="primary" />
        </IconButton>

      </div>
    </Container>
  );


  return (
    <div className="device-detail-container">
      <AdminLayout
        headerTitle={i18n.t("Dispositivo")}
        headerOptions={[]}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default DeviceActivityView;
