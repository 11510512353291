/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FileSaver from "file-saver";
import i18n from "i18n";

import { AuthService } from "services/AuthService";
import { OrderService } from "services/OrderService";
import { MovementService } from "services/MovementServkce";
import { TagService } from "services/TagService";
import { LocationService } from "services/LocationService";
import { AccountService } from "services/AccountService";
import { PersonService } from "services/PersonService";

import { WSService } from "services/WSServices";
import { EventRepository } from "helpers/EventRepository";

import useRequest from "hooks/useRequest";

import "./styles.scss";
import AdminLayout from "../../../../../components/MainApp/layouts/DesktopLayout";
import StepOne from "./steps/Step1";
import StepTwo from "./steps/Step2";
import StepThree from "./steps/Step3";
import LoadFinished from "../../../../../components/MainApp/organisms/Orders/RequestOrders/LoadOrderFinished";
import Wizard from "../../../../../components/MainApp/atoms/Wizard/StepProgress";
import RoundedButton from "../../../../../components/MainApp/atoms/RoundedButton";
import Loader from "../../../../../components/MainApp/atoms/Loader";
import LocationModal from "components/MainApp/organisms/OrdersV3/LocationModal";

import { ReceptionOrder } from "models/Orders";
import { Movement } from "models/Movements";

import {
  itemsByItem,
  itemsByLocation,
  getSelectedItems
} from "helpers/orders/MovementHelper";

import data from "./data";
import ErrorModal from "components/MainApp/organisms/Stock/ErrorModalAuditory";

export const LoadReceptionOrderView = props => {
  const history = useHistory();

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError
  } = useRequest();
  const [activestep, setactivestep] = useState("0");
  // Step 0
  const [order, setorder] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [clients, setClients] = useState([]);
  const [providers, setProviders] = useState([]);
  const [fields, setFields] = useState([]);
  // Step 1
  const [searchedValue, setsearchedValue] = useState("");
  const [movement, setmovement] = useState(null);
  const [movementItems, setmovementItems] = useState([]);
  const [addedList, setaddedList] = useState([]);
  // Step 2
  const [selectDeleteds, setSelectDeleteds] = useState([]);
  const [locateds, setlocateds] = useState([]);
  const [noLocateds, setnoLocateds] = useState([]);
  const [allSelecteds, setallSelecteds] = useState(false);
  const [openLocationForm, setopenLocationForm] = useState(false);
  const [fieldsLocation, setfieldsLocation] = useState([]);
  const [locations, setlocations] = useState([]); // Store the selected locations
  const [locationsOptions, setlocationsOptions] = useState([[]]); // Store the choices
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [ubicacionMessage, setUbicacionMessage] = useState();
  const [validLocation, setValidLocation] = useState(false);

  useEffect(() => {
    if (!props.match.params.key) {
      setorder(new ReceptionOrder(data.initialOrder));
    } else {
      beforeSubmit();
      const id = props.match.params.key;
      OrderService.recOrder(id)
        .then(order => {
          afterSubmit();
          setorder(order);
          if (order.status !== "DRA") {
            getMovement(order.id);
          }
        })
        .catch(error => {
          afterSubmit();
        });
    }
  }, []);

  useEffect(() => {
    const node_id = AuthService.getCurrentNodeCode();

    AccountService.accounts({ nodes: node_id, no_page: 1 }).then(response => {
      const accounts = response.map(a => {
        return { id: a.id, label: `${a.first_name} ${a.last_name}` };
      });
      setAccounts(accounts);
    });

    PersonService.persons({ type: "CLIENT", no_page: 1 }).then(response => {
      const clients = response.map(c => {
        return { id: c.id, label: `${c.name}` };
      });
      setClients(clients);
    });

    PersonService.persons({ type: "PROVIDER", no_page: 1 }).then(response => {
      const providers = response.map(c => {
        return { id: c.id, label: `${c.name}` };
      });
      setProviders(providers);
    });
  }, []);

  useEffect(() => {
    if (!order) return;

    if (activestep === "0") {
      const newFields = order.loadStep1Fields(
        errors,
        accounts,
        clients,
        providers
      );
      setFields(newFields);
    }
  }, [order, accounts, clients, providers]);

  useEffect(() => {
    if (activestep === "1") {
      if (order.status === "DRA") {
        orderToProcess();
      }
    }
  }, [activestep]);

  useEffect(() => {
    if (!movement) return;

    if (movementItems.length === 0) {
      MovementService.items(movement.id).then(items => {
        setmovementItems(items);
      });
    }
  }, [movement]);

  useEffect(() => {
    setValidLocation(false);
  }, [openLocationForm]);

  useEffect(() => {
    setaddedList(itemsByItem(movementItems));
    let allItemsSelecteds = true;

    const itemsLocateds = movementItems.filter(m => m.location);
    setlocateds(itemsByLocation(itemsLocateds));

    let itemsNoLocateds = movementItems.filter(m => !m.location);
    itemsNoLocateds = itemsByItem(itemsNoLocateds);

    for (let item of itemsNoLocateds) {
      for (let item_tag of item.tags) {
        item_tag.selected = selectDeleteds.includes(item_tag.tag);
        if (!item_tag.selected) allItemsSelecteds = false;
      }
    }

    setnoLocateds(itemsNoLocateds);
    setallSelecteds(allItemsSelecteds);
    setSelectDeleteds([]);
  }, [movementItems]);

  const orderToProcess = () => {
    beforeSubmit();
    OrderService.changeOrderStatus(order.id, "PRO", "Creation finished").then(
      _ => {
        order.status = "PRO";
        setorder(order);
        // Consigo el movimiento
        getMovement(order.id);
        afterSubmit();
      }
    );
  };

  const getMovement = (orderId, searchtag) => {
    OrderService.roadMap(orderId).then(response => {
      response = response.filter(result => {
        return result.movement.status !== "CAN";
      });

      if (response.length !== 0) {
        const movement = new Movement(response[response.length - 1].movement);
        setmovement(movement);
        if (searchtag) {
          searchtag(movement);
        }
      } else {
        // El back se encarga de crear al pasar a PRO.
        // Siempre deberia haber movimiento.
      }
    });
  };

  const receiveEvent = async payload => {
    const ws = WSService.getInstance();
    console.log("Message from socket");
    console.log(payload);

    if (payload.method === "EVENT" && payload.room && ws.inRoom(payload.room)) {
      if (
        payload.params.items.length > 0 &&
        !newTag(payload.params.items, payload.params.tag.rfid)
      ) {
        setmovementItems(payload.params.items);
      } else {
        let tag = payload.params.tag ? payload.params.tag.rfid : "";
        getMovement(payload.params.order, function(movement) {
          search(tag, movement);
        });
      }
    }
  };

  const newTag = (items, tag) => {
    return items.find(item => item.tag.rfid == tag) == undefined;
  };

  const restart = () => {
    if (props.match.params.key) {
      history.push("/admin/orders/reception/load");
      return;
    }
    setactivestep("0");
    setorder(new ReceptionOrder(data.initialOrder));
    setmovement(null);
    setmovementItems([]);
  };

  const handleChange = e => {
    let name = e.target.name;
    let newOrder = order;
    newOrder[name] = e.target.value;
    const newFields = newOrder.loadStep1Fields(
      errors,
      accounts,
      clients,
      providers
    );
    setFields(newFields);
    setorder(newOrder);
  };

  const handleSelect = (selectedOption, type) => {
    let orderAux = order;
    let newFields = {};
    switch (type) {
      case "reason":
        orderAux.klass = selectedOption.id;
        newFields = orderAux.loadStep1Fields(
          errors,
          accounts,
          clients,
          providers
        );
        setFields(newFields);
        setorder(orderAux);
        break;
      case "owner":
        orderAux.owner = selectedOption;
        newFields = orderAux.loadStep1Fields(
          errors,
          accounts,
          clients,
          providers
        );
        setFields(newFields);
        setorder(orderAux);
        break;
      case "client":
        orderAux.client = selectedOption;
        newFields = orderAux.loadStep1Fields(
          errors,
          accounts,
          clients,
          providers
        );
        setFields(newFields);
        setorder(orderAux);
        break;
      case "provider":
        orderAux.provider = selectedOption;
        newFields = orderAux.loadStep1Fields(
          errors,
          accounts,
          clients,
          providers
        );
        setFields(newFields);
        setorder(orderAux);
        break;
      default:
        break;
    }
  };

  const searchValue = e => {
    setsearchedValue(e.target.value);
  };

  const handleSearch = e => {
    e.preventDefault();

    if (searchedValue) {
      const epc = searchedValue;
      search(epc, movement);
      setsearchedValue("");
    }
  };

  const search = (epc, movement) => {
    beforeSubmit();
    TagService.itemFromTag(epc)
      .then(item => {
        MovementService.addItems(movement.id, [
          {
            item: item.id,
            tag: epc
          }
        ])
          .then(response => {
            afterSubmit();
            setmovementItems(response);
          })
          .catch(error => {
            afterSubmit();
            dealWithError(error, "generic.error", (message, errors) => {
              setUbicacionMessage("El tag ingresado ya se encuentra en la ubicación " + message);
              setErrorModalOpen(true);
            });
          });
      })
      .catch(_ => {
        afterSubmit();
        EventRepository.notificationSend({
          label: i18n.t("No encontrado el TAG"),
          type: "error"
        });
      });
  };

  const handleDelete = tag => {
    beforeSubmit();
    MovementService.deleteItems(movement.id, [{ id: tag.movement_item_id }])
      .then(response => {
        afterSubmit();
        setmovementItems(response);
      })
      .catch(error => {
        afterSubmit();
      });
  };

  const handleDeleteAll = e => {
    const ids = movementItems.map(m => {
      return { id: m.id };
    });
    beforeSubmit();
    MovementService.deleteItems(movement.id, ids)
      .then(response => {
        afterSubmit();
        setmovementItems(response);
      })
      .catch(error => {
        afterSubmit();
      });
  };

  const handleDeleteLocation = itemLoc => {
    setSelectDeleteds(
      itemLoc.items.map(i => {
        return i.tag;
      })
    );
    const data = itemLoc.items.map(m => {
      return {
        id: m.movement_item_id,
        location: null
      };
    });
    beforeSubmit();
    MovementService.updateItems(movement.id, data)
      .then(response => {
        afterSubmit();
        setmovementItems(response);
      })
      .catch(error => {
        afterSubmit();
      });
  };

  const handleSelectItem = tag => {
    const items = [...noLocateds];
    setallSelecteds(false);

    for (let item of items) {
      for (let item_tag of item.tags) {
        if (item_tag.tag === tag.tag) {
          item_tag.selected = !item_tag.selected;
          const allItemsSelected =
            items
              .map(i => {
                return i.tags.every(tag => {
                  return tag.selected;
                });
              })
              .filter(f => {
                return f === false;
              }).length === 0;
          setallSelecteds(allItemsSelected);
          setnoLocateds(items);
          return;
        }
      }
    }
  };

  const handleSelectAllItem = () => {
    const items = [...noLocateds];

    for (let item of items) {
      for (let item_tag of item.tags) {
        item_tag.selected = !allSelecteds;
      }
    }

    setallSelecteds(!allSelecteds);
    setnoLocateds(items);
  };

  const addLocation = () => {
    if (getSelectedItems(noLocateds).length === 0) return;

    let locationsOptions = [[]]; // No options load
    let fields = [
      {
        type: "dropdown",
        data: {
          label: "Zona Inicial",
          placeholder: "Zona Inicial",
          name: "0",
          selectedOption: "",
          value: "",
          options: []
        }
      }
    ];

    beforeSubmit();
    LocationService.locations_node({ no_parent: "true", no_page: 1 })
      .then(locations => {
        afterSubmit();
        locations = locations.map(l => {
          return {
            id: l.id,
            label: `${l.name}`,
            type: `${l.type}`,
            capacity: l.capacity,
            capacity_busy: l.capacity_busy
          };
        });
        locationsOptions[0] = locations;
        fields[0]["data"]["options"] = locations;

        setlocations([]);
        setlocationsOptions(locationsOptions);
        setfieldsLocation(fields);
        setopenLocationForm(true);
      })
      .catch(e => {
        afterSubmit();
      });
  };

  const handleSelectLocation = (loc, name) => {
    const pos = parseInt(name);
    const newPos = pos + 1;
    let fields = fieldsLocation.slice(0, pos + 1);
    let locationsNew = locations.slice(0, pos + 1);
    let locationsOptionsNew = locationsOptions.slice(0, pos + 1);

    locationsNew[pos] = loc;
    fields[pos]["data"]["selectedOption"] = loc;
    fields[pos]["data"]["value"] = loc.id;

    beforeSubmit();
    LocationService.locations_node({ parent: loc.id, no_page: 1 })
      .then(locations => {
        afterSubmit();
        setValidLocation(locations.length === 0);
        if (locations.length === 0) {
          throw "Empty";
        }

        locations = locations.map(l => {
          return {
            id: l.id,
            label: `${l.name}`,
            type: `${l.type}`,
            capacity: l.capacity,
            capacity_busy: l.capacity_busy
          };
        });
        locationsOptionsNew[newPos] = locations;
        fields.push({
          type: "dropdown",
          data: {
            label: `${loc.label}`,
            placeholder: `${loc.label}`,
            name: String(newPos),
            selectedOption: "",
            value: "",
            options: locations
          }
        });

        setlocations(locationsNew);
        setlocationsOptions(locationsOptionsNew);
        setfieldsLocation(fields);
      })
      .catch(e => {
        afterSubmit();

        setlocations(locationsNew);
        setlocationsOptions(locationsOptionsNew);
        setfieldsLocation(fields);
      });
  };

  async function getCounters(data) {
    let auxData = [...data];
    const counts = await TagService.countByTags(auxData);
    for (let d of auxData) {
      d["count"] = counts[d.tag];
    }
    return auxData;
  }

  const handleAddLocation = () => {
    if (!validLocation) {
      EventRepository.notificationSend({
        label: i18n.t("locations.invalid_location"),
        type: "error"
      });
      return;
    }
    const loc = locations[locations.length - 1];
    /* TODO: Verificar porque se bloqueaba la opcion de seleccionar estanterias o niveles.
    if (["SHELF", "LEVEL"].includes(loc.type)) {
      EventRepository.notificationSend({
        label: i18n.t("locations.invalid_location"),
        type: "error",
      });
      return;
    }*/

    const data = getSelectedItems(noLocateds).map(i => {
      return {
        id: i.movement_item_id,
        location: loc.id,
        tag: i.tag
      };
    });
    beforeSubmit();
    getCounters(data).then(data => {
      let totalCount = data.reduce((partialSum, a) => partialSum + a.count, 0);

      const busies = locations
        .filter(l => {
          if (l.capacity === null) {
            return false;
          }

          let cuantityLocated = 0;
          movementItems.forEach(item => {
            if (item.location && item.location.id == l.id) {
              cuantityLocated += item.count;
            }
          });

          l.capacity_busy = l.capacity_busy + cuantityLocated;

          return l.capacity_busy + totalCount > l.capacity;
        })
        .reverse();

      if (busies.length) {
        EventRepository.notificationSend({
          label: i18n.t("locations.max_capacity", {
            overflow: busies[0].capacity_busy + totalCount - busies[0].capacity,
            capacity: busies[0].capacity,
            type: i18n.t(`locations.type.${busies[0].type}`),
            name: busies[0].label
          }),
          type: "error"
        });
        afterSubmit();
        return;
      }

      MovementService.updateItems(movement.id, data)
        .then(response => {
          afterSubmit();
          setmovementItems(response);
          setopenLocationForm(false);
        })
        .catch(error => {
          afterSubmit();
          setopenLocationForm(false);
          setallSelecteds(false);
        });
    });
  };

  const stepChangeHandler = step => {
    setactivestep(step);
  };

  const step0IsOk = () => {
    return (
      fields &&
      fields.findIndex(field => {
        return (
          field.data.required && (field.data.value === "" || !field.data.value)
        );
      }) === -1
    );
  };

  const step1IsOk = () => {
    return addedList.length > 0;
  };

  const nextStep = e => {
    e.preventDefault();
    const func = {
      "0": e => stepOneSend(e),
      "1": e => stepTwoSend(e),
      "2": e => handleSubmit(e)
    };
    func[activestep](e);
  };

  const stepOneSend = e => {
    e.preventDefault();

    const data = order.dataToSend();
    beforeSubmit();
    if (order.id) {
      OrderService.updateOrder(order.id, data)
        .then(order => {
          afterSubmit();
          setorder(new ReceptionOrder(order));
          stepChangeHandler("1");
        })
        .catch(error => {
          afterSubmit();
          dealWithError(error, "generic.error");
        });
    } else {
      OrderService.createOrder(data)
        .then(order => {
          afterSubmit();
          setorder(new ReceptionOrder(order));
          stepChangeHandler("1");
        })
        .catch(error => {
          afterSubmit();
          dealWithError(error, "generic.error");
        });
    }
  };

  const stepTwoSend = e => {
    e.preventDefault();
    stepChangeHandler("2");
  };

  const handleSubmit = e => {
    e.preventDefault();

    let tagsList = [];
    movementItems?.forEach(item => tagsList.push(item.tag?.rfid));

    // Completo la orden
    beforeSubmit();
    MovementService.changeStatus(movement.id, "COM", tagsList)
      .then(response => {
        afterSubmit();
        setactivestep("finish");
      })
      .catch(error => {
        afterSubmit();
        error.json().then(data => {
          if(data?.detail?.includes("Invalid tag")) {
            let msg = data?.detail.replace("Invalid tag", data?.detail.match(/,/g)?.length > 0 ? "Tags inválidos:" : "Tag inválido:");
            setErrorModalOpen(true);
            setUbicacionMessage(msg.replace(/['"\[\]]+/g, ''));
          }else {
            if(data.detail) {
              EventRepository.notificationSend({label: i18n.t(data.detail),type: "error"});
            }
          }
        });
      });
  };

  const downloadVoucher = () => {
    beforeSubmit();
    OrderService.recDownloadVoucher(order.id)
      .then(blob => {
        afterSubmit();
        FileSaver.saveAs(blob, "comprobante.pdf");
      })
      .catch(error => {
        afterSubmit();
      });
  };

  const goBackFunc = () => {
    history.push("/admin/orders/reception");
  };

  const content = (
    <div className="load-reception-order-content">
      {activestep !== "finish" ? (
        <div className="load-reception-order-wrapper">
          <div className="load-reception-order-wizard">
            <Wizard
              steps={data.steps}
              activeStep={activestep}
              clickEnabled={false}
            ></Wizard>
          </div>

          <div className="load-reception-order-step">
            {activestep === "0" && (
              <form onSubmit={nextStep} className="content">
                <StepOne
                  order={order}
                  handleChange={handleChange}
                  handleSelect={handleSelect}
                  fields={fields}
                />
                <div className="load-reception-order-button">
                  <div>
                    <RoundedButton
                      legend="CONTINUAR"
                      type="submit"
                      state={step0IsOk() ? "enabled" : "disabled"}
                      handleClick={nextStep}
                    ></RoundedButton>
                  </div>
                </div>
              </form>
            )}

            {activestep === "1" && (
              <div className="content">
                <StepTwo
                  originalItems={movementItems}
                  selectedItems={addedList}
                  searchValue={searchValue}
                  searchedValue={searchedValue}
                  handleSearch={handleSearch}
                  handleDelete={handleDelete}
                  handleDeleteAll={handleDeleteAll}
                  handleSend={nextStep}
                  enabledRead={true}
                  typeRead={"ORDERS"}
                  refRead={order.id}
                  associateRead={true}
                  priorityRead={"BARCODE"}
                  receiveEvent={receiveEvent}
                />
                <div className="load-movement-items-buttons-wrapper">
                  <div
                    className="load-movement-items-button load-movement-items-clean"
                    onClick={handleDeleteAll}
                  >
                    LIMPIAR
                  </div>
                  <div
                    className={`load-movement-items-button load-movement-items-send ${
                      step1IsOk() ? "enabled" : "disabled"
                    }`}
                    onClick={nextStep}
                  >
                    CONTINUAR
                  </div>
                </div>
              </div>
            )}

            {activestep === "2" && (
              <div className="content">
                <StepThree
                  itemsLocateds={locateds}
                  itemsNoLocateds={noLocateds}
                  handleDelete={handleDeleteLocation}
                  handleSelect={handleSelectItem}
                  allSelecteds={allSelecteds}
                  handleSelectAll={handleSelectAllItem}
                />
                <div className="load-reception-order-button last">
                  <div>
                    {noLocateds.length !== 0 && (
                      <RoundedButton
                        legend="AGREGAR UBICACION"
                        state="enabled"
                        handleClick={addLocation}
                      ></RoundedButton>
                    )}
                    {noLocateds.length === 0 && (
                      <RoundedButton
                        legend="CREAR ORDEN"
                        state="enabled"
                        handleClick={handleSubmit}
                      ></RoundedButton>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {openLocationForm && (
            <LocationModal
              fields={fieldsLocation}
              handleClose={() => setopenLocationForm(false)}
              handleSelect={handleSelectLocation}
              handleAdd={handleAddLocation}
            />
          )}
        </div>
      ) : (
        <div className="load-reception-order-wrapper-finish">
          <div className="load-reception-order-wrapper-finish-container">
            <LoadFinished
              orderNumber={order.code}
              boldButtonFunc={restart}
              downloadVoucher={downloadVoucher}
              buttonLink={"/admin/orders/reception"}
            />
          </div>
        </div>
      )}

      <ErrorModal
        open={errorModalOpen}
        message={ubicacionMessage}
        handleClose={() => {
          setErrorModalOpen(false);
        }}
        handleButtonClick={() => {
          setErrorModalOpen(false);
        }}
        handleGoBack={() => {
          setErrorModalOpen(false);
        }}
      />
    </div>
  );

  return (
    <div className="load-reception-order-container">
      <AdminLayout
        headerTitle={"Nueva orden de entrada"}
        headerOptions={data.headerOptions}
        content={content}
        goBackFunc={activestep !== "finish" ? goBackFunc : null}
        navHidden={true}
      ></AdminLayout>

      {loading && <Loader />}

      {/* <SingleLoadExitModal
          open={this.state.goBackConfirm}
          confirm={() => this.props.history.push("/admin/products/load")}
          cancel={() => this.setState({ goBackConfirm: false })}
          handleClose={() => this.setState({ goBackConfirm: false })}
        /> */}
    </div>
  );
};

export default LoadReceptionOrderView;
