import React from "react";
import { Route, Redirect } from "react-router-dom";
import "./App.css";
import "./assets/fontello/css/fontello.css";

import history from "./history";
import { AuthService } from "./services/AuthService";
import { EventRepository } from "./helpers/EventRepository";
import { WSService } from "./services/WSServices";

import ProtectedRoute from "./components/Auth/atoms/auth/ProtectedRoute";
import ProtectedRouteNode from "./components/Auth/atoms/auth/ProtectedRouteNode";

import { SignInView } from "./views/Auth/SignInView";
import { CreateAccountView } from "./views/Auth/CreateAccount";
import { ForgottenPasswordView } from "./views/Auth/ForgottenPassword";
import { ResetPasswordView } from "./views/Auth/ResetPassword";
import { EmailVerificationView } from "./views/Auth/EmailVerification";
import { ConfirmMailView } from "./views/Auth/ConfirmMail";
import { ProductsView } from "./views/Admin/Products";
import { LoadProductsView } from "./views/Admin/Products/LoadProducts";
import { LoadSingleProductView } from "./views/Admin/Products/LoadSingleProduct";
import { LoadProductInfoView } from "./views/Admin/Products/LoadProductInfo";
import { LoadMassiveProductView } from "./views/Admin/Products/LoadMassiveProduct";
import { AddCategoryView } from "./views/Admin/Categories/AddCategory";
import { CategoriesView } from "./views/Admin/Categories";
import { ProductDetailsView } from "./views/Admin/Products/ProductDetails";
import { OrdersView } from "./views/Admin/Orders";
import { RequestOrdersView } from "./views/Admin/Orders/RequestOrders";
import RequestOrderDetailsView from "./views/Admin/Orders/RequestOrders/OrderDetail";
import { LoadRequestOrderView } from "./views/Admin/Orders/RequestOrders/LoadRequestOrder";

import { PrepOrdersView } from "./views/Admin/Orders/PrepOrders";
import PrepOrderDetailsView from "./views/Admin/Orders/PrepOrders/OrderDetail";
import LoadPrepOrderView from "views/Admin/Orders/PrepOrders/LoadPrepOrder";

import { RecOrdersView } from "./views/Admin/Orders/RecOrders";
import ReceptionOrderDetailView from "./views/Admin/Orders/RecOrders/ReceptionOrderDetail";
import LoadReceptionOderView from "./views/Admin/Orders/RecOrders/LoadReceptionOrder";

import RelocationOrdersView from "./views/Admin/Orders/RelocationOrders";
import RelocationOrderDetailsView from "views/Admin/Orders/RelocationOrders/RelocationOrderDetail";
import LoadRelocationOrderView from "views/Admin/Orders/RelocationOrders/LoadRelocationOrder";

import { BusinessNodeListView } from "./views/Admin/BusinessNode/BusinessNodeList";
import AddBusinessNode from "./views/Admin/BusinessNode/AddBusinessNode";
import BusinessNodeDetailView from "./views/Admin/BusinessNode/BusinessNodeDetail";
import SetupLocationsView from "./views/Admin/BusinessNode/SetupLocations";
import AddLocationView from "./views/Admin/Locations/AddLocation";
import LocationDetailView from "./views/Admin/Locations/LocationDetail";

import { ConfigTagListView } from "./views/Admin/Tag/TagList";
import { TraceabilityTags } from "./views/Admin/Tag/TagTraceability"
import ConfigTagDetailView from "./views/Admin/Tag/TagDetail";
import AddConfigTagView from "./views/Admin/Tag/AddConfigTag";
import AddTagView from "./views/Admin/Tag/AddTag";
import EditTagView from "./views/Admin/Tag/EditConfigTag";

import { AssociateTagsView } from "./views/Admin/Tag/AssociateTags";

import { DevicesListView } from "./views/Admin/Devices";
import DeviceDetailView from "./views/Admin/Devices/DeviceDetail";
import DeviceActivityView from "./views/Admin/Devices/DeviceActivity"
import ShelvingActivityView2 from "./views/Admin/Devices/ShelvingActivity2"
import FlowActivityView from "./views/Admin/Devices/FlowActivity"
import GuardActivityView from "./views/Admin/Devices/Guard"
import AddDevice from "./views/Admin/Devices/AddDevice";
import EditDevice from "./views/Admin/Devices/EditDevice";
import AntennaDetailView from "./views/Admin/Devices/AntennaDetail";

import { ClientListView } from "./views/Admin/Clients";
import AddClient from "./views/Admin/Clients/AddClient";
import ClientDetailView from "./views/Admin/Clients/ClientDetail";
import EditClientView from "./views/Admin/Clients/EditClient";

import { ProviderListView } from "./views/Admin/Providers";
import AddProvider from "./views/Admin/Providers/AddProvider";
import ProviderDetailView from "./views/Admin/Providers/ProviderDetail";
import EditProviderView from "./views/Admin/Providers/EditProvider";

import ExtraMenuView from "./views/Admin/ExtraMenu";
import AccountView from "./views/Admin/Account";
import ActiveNodeView from "./views/Admin/ActiveNode";

import Stock from "./views/Admin/Stock";
import FichaDeStock from "./views/Admin/Stock/FichaDeStock";
import AuditoriaDeStock from "./views/Admin/Stock/AuditoriaDeStock";
import LocalizationTagView from "./views/Admin/Stock/LocalizationTag";
import LocalizationProductView from "views/Admin/Stock/LocalizationProduct";

import ApplicationsView from "./views/Admin/Apps";
import EditApplicationView from "./views/Admin/Apps/EditApp";
import AddApplicationView from "./views/Admin/Apps/AddApp";

import { BusinessesView } from "./views/Admin/Businesses";
import AddBusinessView from "./views/Admin/Businesses/LoadBusiness";
import EditBusinessView from "./views/Admin/Businesses/EditBusiness";

import UsersView from "./views/Admin/Users";
import AddUsersView from "./views/Admin/Users/AddUser";
import EditUsersView from "./views/Admin/Users/EditUser";

import RolesView from "./views/Admin/Users/Roles";
import AddRolesView from "./views/Admin/Users/Roles/AddRole";
import EditRoleView from "./views/Admin/Users/Roles/EditRole";

import EmbalajesTableView from "./views/Admin/Products/Embalajes";
import AddEmbalajeView from "./views/Admin/Products/Embalajes/AddEmbalaje";
import EmbalajeDetailView from './views/Admin/Products/Embalajes/EmbalajeDetail';

import Dashboard from "./views/Admin/Dashboard";

import MainNotification from "./components/MainApp/atoms/Notification";

import { RFIDContext, RFIDContextPersist } from "./store/context";
import { ConfigTag } from "./models/Tag";
import ExtraMenuTagsView from "views/Admin/Tag/ExtraMenuTags";
import { FirebaseYaxDevicesService } from "services/FirebaseYaxDevicesService";
import ReprintTagView from "views/Admin/Tag/ReprintTag";
import EditCodeTagView from "views/Admin/Tag/EditCodeTag";
import SimplifedRelocation from "views/Admin/Orders/RelocationOrders/Simplifed";
import MainTabs from "views/Admin/ManageYaxDevices/MainTabs";

class App extends React.Component {
  notificationShow = null;

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      load: false,

      notification: {
        open: false,
        label: null,
        type: null,
        closeEvent: () => {},
      },
    };
  }

  componentDidMount() {
    let fyds = new FirebaseYaxDevicesService();
    AuthService.loadUser()
      .then((user) => {
        this.setState({
          user: user,
          load: true,
        });
      })
      .catch((error) => {
        this.setState({ load: true });
      });

    this.currentUserObserver = AuthService.currentUser.subscribe((user) => {
      if (this.state.user != null && user === null) {
        history.push("/login");
      }
      // Obligo a renderizar entonces refresca bien los permisos
      this.setState({
        user: user,
      });

      if (user) {
        // Inicializa la conexion con el WS
        this.ws = new WSService();

      } else if (this.ws) {
        // Cierro conexion y elimino singleton
        this.ws.disconnect();
      }
    });

    this.notificationObserver = EventRepository.notificationObservable.subscribe(
      (data) => {
        if (!data) {
          return;
        }

        const notification = {
          open: true,
          ...data,
          closeEvent: ()=>{
            const notification = { open: false };
            this.setState({
              notification,
            });
          }
        };
        this.setState({
          notification,
        });
        clearTimeout(this.notificationShow);
        this.notificationShow = setTimeout(() => {
          const notification = { open: false };
          this.setState({
            notification,
          });
        }, 5000);
      }
    );
    this.changeFontSize();
  }

  changeFontSize() {
    // Función para cambiar el tamaño de letra
    const updateFontSize = () => {
      var elements = document.querySelectorAll('*');
      elements.forEach(function(element) {
        const fontSize = window.getComputedStyle(element).fontSize;
        const fontSizeValue = parseFloat(fontSize);
        const fontSizeUnit = fontSize.replace(fontSizeValue, '');

        if (fontSizeUnit === 'px' && fontSizeValue > 8 && fontSizeValue < 20
          && !element.classList.contains('font-size-updated')) {
          element.style.fontSize = `${fontSizeValue + 3}px`;
          element.classList.add('font-size-updated'); // Marcar el elemento como actualizado
        }
      });
    };

    // Verificar si la orientación es portrait y el ancho está entre 700px y 1200px
    const mediaQuery = window.matchMedia('(min-width: 700px) and (max-width: 1200px) and (orientation: portrait)');
    if (mediaQuery.matches) {
      // Ejecutar la función inicialmente
      updateFontSize();

      // Crear un MutationObserver para observar cambios en el DOM
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList' || mutation.type === 'subtree') {
            updateFontSize();
          }
        });
      });

      // Configurar el observer para observar cambios en el body
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });

      // Guardar el observer en el estado para poder desconectarlo en componentWillUnmount
      this.observer = observer;
    }
  }

  componentWillUnmount() {
    this.currentUserObserver.unsubscribe();
    this.notificationObserver.unsubscribe();
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  render() {
    return (
      <RFIDContextPersist>
        <RFIDContext>
          <main>
            {this.state.load && (
              <div>
                <Route path="/login" component={SignInView} />
                <Route path="/create" component={CreateAccountView} />
                <Route path="/forgotten" component={ForgottenPasswordView} />
                <Route path="/reset/:key" component={ResetPasswordView} />
                <Route
                  path="/email-verification"
                  render={(props) => <EmailVerificationView {...props} />}
                />
                <Route path="/confirm/:user/:key" component={ConfirmMailView} />

                <ProtectedRoute
                  exact
                  path="/admin/account"
                  component={AccountView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/active-node"
                  component={ActiveNodeView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/more-options"
                  component={ExtraMenuView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/more-options/:stepNumber"
                  component={ExtraMenuView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/more-options-tags"
                  component={ExtraMenuTagsView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/products"
                  component={ProductsView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/products/load"
                  component={LoadProductsView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/products/load-product-info"
                  component={LoadProductInfoView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/products/load-single-product"
                  component={LoadSingleProductView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/products/load-massive-product"
                  component={LoadMassiveProductView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/categories"
                  component={CategoriesView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/categories/add-category"
                  component={AddCategoryView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/products/details/:key"
                  component={ProductDetailsView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/orders"
                  component={OrdersView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/orders/request"
                  component={RequestOrdersView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/orders/request/details/:key"
                  component={RequestOrderDetailsView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/orders/request/load"
                  component={LoadRequestOrderView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/orders/request/edit/:key"
                  component={LoadRequestOrderView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/orders/preparation"
                  component={PrepOrdersView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/orders/preparation/load"
                  component={LoadPrepOrderView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/orders/preparation/details/:key/:node?"
                  component={PrepOrderDetailsView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/orders/preparation/edit/:key"
                  component={LoadPrepOrderView}
                />

                <ProtectedRouteNode
                  exact
                  path="/admin/orders/reception"
                  component={RecOrdersView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/orders/reception/load"
                  component={LoadReceptionOderView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/orders/reception/edit/:key"
                  component={LoadReceptionOderView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/orders/reception/details/:key/:node?"
                  component={ReceptionOrderDetailView}
                />

                <ProtectedRouteNode
                  exact
                  path="/admin/orders/relocation"
                  component={RelocationOrdersView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/orders/relocation/load"
                  component={LoadRelocationOrderView}
                />
                 <ProtectedRouteNode
                  exact
                  path="/admin/orders/relocation/simplifed"
                  component={SimplifedRelocation}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/orders/relocation/edit/:key"
                  component={LoadRelocationOrderView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/orders/relocation/details/:key"
                  component={RelocationOrderDetailsView}
                />


                <ProtectedRoute
                  exact
                  path="/admin/businessnodes"
                  component={BusinessNodeListView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/businessnodes/edit/:key"
                  component={AddBusinessNode}
                />
                <ProtectedRoute
                  exact
                  path="/admin/businessnodes/add"
                  component={AddBusinessNode}
                />
                <ProtectedRoute
                  exact
                  path="/admin/businessnodes/detail/:key"
                  component={BusinessNodeDetailView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/businessnodes/locationssetup/:key"
                  component={SetupLocationsView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/locations/add"
                  component={AddLocationView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/locations/edit/:key"
                  component={AddLocationView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/locations/detail/:key"
                  component={LocationDetailView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/config_tags"
                  component={ConfigTagListView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/tag-traceability"
                  component={TraceabilityTags}
                />
                <ProtectedRoute
                  exact
                  path="/admin/config_tags/detail/:key"
                  component={ConfigTagDetailView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/config_tags/add"
                  component={AddConfigTagView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/config_tags/edit/:key"
                  component={EditTagView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/code_tags/edit/:key"
                  component={EditCodeTagView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/tags/print/:key"
                  component={AddTagView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/tags/reprint/:epc?"
                  component={ReprintTagView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/tags/associate"
                  component={AssociateTagsView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/devices"
                  component={DevicesListView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/devices/detail/:key"
                  component={DeviceDetailView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/devices/add/"
                  component={AddDevice}
                />

                <ProtectedRoute
                  exact
                  path="/admin/devices/add/antenna"
                  component={EditDevice}
                />
                <ProtectedRoute
                  exact
                  path="/admin/devices/edit/:key"
                  component={EditDevice}
                />
                 <ProtectedRoute
                  exact
                  path="/admin/devices/activity"
                  component={DeviceActivityView}
                />
                 <ProtectedRoute
                  exact
                  path="/admin/devices/shelvingactivity"
                  component={ShelvingActivityView2}
                />
                <ProtectedRoute
                  exact
                  path="/admin/devices/flowactivity"
                  component={FlowActivityView}
                />
                 <ProtectedRoute
                  exact
                  path="/admin/devices/guardactivity"
                  component={GuardActivityView}
                />

                <ProtectedRoute
                  exact
                  path="/admin/devices/detail/antenna/:key"
                  component={AntennaDetailView}
                />

                <ProtectedRoute
                  exact
                  path="/admin/clients"
                  component={ClientListView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/clients/add/"
                  component={AddClient}
                />
                <ProtectedRoute
                  exact
                  path="/admin/clients/detail/:key"
                  component={ClientDetailView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/clients/edit/:key"
                  component={EditClientView}
                />

                <ProtectedRoute
                  exact
                  path="/admin/providers"
                  component={ProviderListView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/providers/add/"
                  component={AddProvider}
                />
                <ProtectedRoute
                  exact
                  path="/admin/providers/detail/:key"
                  component={ProviderDetailView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/providers/edit/:key"
                  component={EditProviderView}
                />

                <ProtectedRouteNode
                  exact
                  path="/admin/stock"
                  component={Stock}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/ficha-de-stock"
                  component={FichaDeStock}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/auditoria-de-stock"
                  component={AuditoriaDeStock}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/localization-tag"
                  component={LocalizationTagView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/localization-product"
                  component={LocalizationProductView}
                />
                <ProtectedRouteNode
                  exact
                  path="/admin/applications"
                  component={ApplicationsView}
                />

                <ProtectedRouteNode
                  exact
                  path="/admin/applications/add"
                  component={AddApplicationView}
                />

                <ProtectedRouteNode
                  exact
                  path="/admin/applications/edit/:key"
                  component={EditApplicationView}
                />

                <ProtectedRoute
                  exact
                  path="/admin/businesses"
                  component={BusinessesView}
                />

                <ProtectedRoute
                  exact
                  path="/admin/businesses/add"
                  component={AddBusinessView}
                />

                <ProtectedRoute
                  exact
                  path="/admin/businesses/edit/:key"
                  component={EditBusinessView}
                />


                <ProtectedRoute
                  exact
                  path="/admin/users"
                  component={UsersView}
                />

                <ProtectedRoute
                  exact
                  path="/admin/users/add"
                  component={AddUsersView}
                />

                <ProtectedRoute
                  exact
                  path="/admin/users/edit/:key"
                  component={EditUsersView}
                />


                <ProtectedRoute
                  exact
                  path="/admin/roles"
                  component={RolesView}
                />

                <ProtectedRoute
                  exact
                  path="/admin/roles/add"
                  component={AddRolesView}
                />

                <ProtectedRoute
                  exact
                  path="/admin/roles/edit/:key"
                  component={EditRoleView}
                />

                <ProtectedRoute
                  exact
                  path="/admin/embalajes"
                  component={EmbalajesTableView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/embalajes/add"
                  component={AddEmbalajeView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/embalajes/detail/:key"
                  component={EmbalajeDetailView}
                />
                <ProtectedRoute
                  exact
                  path="/admin/embalajes/edit/:key"
                  component={AddEmbalajeView}
                />

                <ProtectedRouteNode
                  exact
                  path="/admin/dashboard"
                  component={Dashboard}
                />

                <ProtectedRouteNode
                  exact
                  path="/admin/yaxdevices"
                  component={MainTabs}
                />

                <Route exact path="/admin">
                  <Redirect to="/admin/dashboard" />
                </Route>
                <Route exact path="/">
                  {AuthService.currentUserValue() ? (
                    <Redirect to="/admin/dashboard" />
                  ) : (
                    <Redirect to="/login" />
                  )}
                </Route>
              </div>
            )}

            <MainNotification
              open={this.state.notification.open}
              label={this.state.notification.label}
              type={this.state.notification.type}
              closeEvent={this.state.notification.closeEvent}
            />
          </main>
        </RFIDContext>
      </RFIDContextPersist>
    );
  }
}

export default App;
